<template>
  <div>
    <form-wizard
      :color="is_loading.submit ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="is_loading.submit ? 'Saving...' : 'Save'"
      class="mb-3"
      @on-complete="formSubmitted()"
    >
      <!-- ticket details tab -->
      <tab-content
        title="Ticket Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="ticket-form" class="p-2">
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <label class="required-l" for="ticket-category">
                    Ticket Category
                  </label>
                  <v-select
                    v-model="data_local.category_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="support_categories"
                    :clearable="false"
                    :searchable="false"
                    :reduce="(val) => val.code"
                    label="value"
                    input-id="ticket-category"
                    name="category_id"
                    placeholder="Select Ticket Category"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <label class="required-l" for="priority"> Priority </label>
                  <v-select
                    v-model="data_local.priority"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="priority_option"
                    :reduce="(val) => val.value"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="priority"
                    name="priority"
                    placeholder="Select Priority"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" v-if="false">
                <b-form-group>
                  <label class="required-l" for="status"> Status </label>
                  <v-select
                    v-model="data_local.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="status_option"
                    :reduce="(val) => val.value"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="status"
                    name="status"
                    placeholder="Select Status"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Subject"
                    rules="required"
                  >
                    <label class="required-l" for="subject">Subject</label>
                    <b-form-input
                      id="subject"
                      v-model="data_local.subject"
                      name="subject"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Subject"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label class="required-l" for="description">
                    Description
                  </label>
                  <b-form-textarea
                    id="description"
                    v-model="data_local.description"
                    name="description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12" class="">
                <b-form-group label="Attachments" label-for="files">
                  <file-pond
                    ref="pond"
                    name="files[]"
                    max-file-size="5MB"
                    max-files="10"
                    allow-multiple="true"
                     v-bind:files="myFiles"
                    image-preview-height="120px"
                    accepted-file-types="image/*,application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    file-validate-type-label-expected-types="Expects a PDF, DOC or DOCX file"
                  />
                  <small class="text-warning flex items-center">
                    <feather-icon icon="AlertCircleIcon" class="mr-25" />
                    File size should be less than 5MB and allowed formats are
                    <b>.doc, .docx, .pdf, image.</b>
                  </small>
                </b-form-group>
              </b-col>
              <b-form-group
                v-for="(item, index) in customFields"
                :key="item.id"
                class="col-md-6"
              >
                <!-- #default="{ errors }" -->
                <validation-provider
                  :name="item.name"
                  rules=""
                  #default="validationContext"
                >
                  <b-form-group v-if="item.type == 'number'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'text'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'url'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="url"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'rating'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <div class="d-flex align-items-center">
                      <star-rating
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :show-rating="false"
                        :star-size="30"
                      ></star-rating>
                      <span
                        v-if="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :class="`rating-btn btn ${
                          ratingdescription[
                            data_local.get_customfields_data['field_' + item.id]
                          ].class
                        }`"
                      >
                        {{
                          ratingdescription[
                            data_local.get_customfields_data["field_" + item.id]
                          ].text
                        }}</span
                      >
                      <input
                        type="hidden"
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group v-if="item.type == 'password'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="password"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'textarea'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-textarea
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      trim
                      :placeholder="item.label"
                    />
                  </b-form-group>
                  <b-form-group v-if="item.type == 'date'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <flat-pickr
                      :config="{ dateFormat: 'Y-m-d' }"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      class="form-control"
                      placeholder="Select Date.."
                    />
                  </b-form-group>

                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import store from "@/store";
import ticketStoreModule from "../ticketStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";

// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    ToastificationContent,
    StarRating,
    flatPickr,
    FilePond,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      is_loading: {
        submit: false,
      },
      redirectTicket: "",
      data_local: {
        priority: "medium",
        status: "open",
        get_customfields_data: [],
      },
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles:[]
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-tickets";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
  },

  methods: {
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("ticket-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      formData.append("category_id", self.data_local.category_id);
      
      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      this.is_loading.submit = true;
      this.$store
        .dispatch("app-tickets/addTicket", formData)
        .then((res) => {
          // Add this ticket to NHR
          this.is_loading.submit = false;
          this.$router.push("/tickets");
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Ticket Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.is_loading.submit = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Ticket Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Ticket Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-tickets/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.ticketStatusOption = res.data.meta.status;
          self.support_categories = res.data.support_categories;
          self.priority_option = res.data.priority_select;
          self.status_option = res.data.status_select;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
  position: absolute;
  right: 180px;
  bottom: 60px;
  border: solid 1px;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
